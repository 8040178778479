class ColorService {
	static lightenColor(color, percent) {
		const num = parseInt(color.slice(1), 16);
		const amt = Math.min(255, Math.round(255 * (percent / 100)));
		const R = Math.min(255, (num >> 16) + amt);
		const G = Math.min(255, ((num >> 8) & 0x00ff) + amt);
		const B = Math.min(255, (num & 0x0000ff) + amt);
		return '#' + (((R & 0xff) << 16) | ((G & 0xff) << 8) | (B & 0xff)).toString(16).padStart(6, '0').toUpperCase();
	}

	static darkenColor(color, percent) {
		const num = parseInt(color.slice(1), 16);
		const amt = Math.round(2.55 * percent);
		const R = Math.max(0, (num >> 16) - amt);
		const G = Math.max(0, ((num >> 8) & 0x00ff) - amt);
		const B = Math.max(0, (num & 0x0000ff) - amt);
		return '#' + (((R & 0xff) << 16) | ((G & 0xff) << 8) | (B & 0xff)).toString(16).padStart(6, '0').toUpperCase();
	}

	static opacityColor(color, opacity) {
		const num = parseInt(color.slice(1), 16);
		const R = num >> 16;
		const G = (num >> 8) & 0x00ff;
		const B = num & 0x0000ff;
		return `rgba(${R},${G},${B},${opacity})`;
	}

	static hueShift(color, degree) {
		const hsl = ColorService.rgbToHsl(color);
		hsl[0] = (hsl[0] + degree) % 360;
		return ColorService.hslToRgb(hsl);
	}

	static desaturateColor(color, percent) {
		const hsl = ColorService.rgbToHsl(color);
		hsl[1] = Math.max(0, hsl[1] - percent);
		return ColorService.hslToRgb(hsl);
	}

	static rgbToHsl(color) {
		const r = parseInt(color.slice(1, 3), 16) / 255;
		const g = parseInt(color.slice(3, 5), 16) / 255;
		const b = parseInt(color.slice(5, 7), 16) / 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let h;
		let s;
		const l = (max + min) / 2;

		if (max === min) {
			h = 0;
			s = 0;
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}

			h /= 6;
		}

		return [h * 360, s * 100, l * 100];
	}

	static hslToRgb(hsl) {
		const h = hsl[0] / 360;
		const s = hsl[1] / 100;
		const l = hsl[2] / 100;
		let r;
		let g;
		let b;

		if (s === 0) {
			r = g = b = l;
		} else {
			const hue2rgb = (p, q, t) => {
				if (t < 0) t += 1;
				if (t > 1) t -= 1;
				if (t < 1 / 6) return p + (q - p) * 6 * t;
				if (t < 1 / 2) return q;
				if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
				return p;
			};

			const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
			const p = 2 * l - q;
			r = hue2rgb(p, q, h + 1 / 3);
			g = hue2rgb(p, q, h);
			b = hue2rgb(p, q, h - 1 / 3);
		}

		return `#${Math.round(r * 255)
			.toString(16)
			.padStart(2, '0')}${Math.round(g * 255)
				.toString(16)
				.padStart(2, '0')}${Math.round(b * 255)
					.toString(16)
					.padStart(2, '0')}`;
	}
}

export default ColorService;
