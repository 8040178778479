import { create } from 'zustand';

interface IEnv {
	cpool?: string;
	ccid?: string;
	rev?: string;
	arena?: string;
	c_region?: string;
	c_domain?: string;
	c_identities?: [string];
	c_identity_callback_url?: string;
	c_is_2fa_required?: boolean;
	c_is_remember_device?: boolean;
	color_accent?: string;
	color_shift?: string;
	avatar?: string;
	logo?: string;
}

interface IEnvStore {
	env: IEnv;
	setEnv: (env: IEnv) => void;
}

export const useEnvironmentStore = create<IEnvStore>()((set) => ({
	env: {},
	setEnv: (env: IEnv) => set(() => ({ env })),
}));

const loadEnvironment = () =>
	fetch('/api/env')
		.then((response) => {
			if (!response.ok) {
				throw new Error('Failed to load environment settings: ' + response.status);
			}

			return response.json() as IEnv;
		})
		.catch((error) => {
			console.error('Failed to load environment:', error);
			throw error;
		});

let environmentPromise: Promise<IEnv> | null = null;

export function getEnvironment() {
	if (!environmentPromise) {
		environmentPromise = loadEnvironment();
		environmentPromise.then((env) => {
			useEnvironmentStore.getState().setEnv(env);
		});
	}
	return environmentPromise;
}
