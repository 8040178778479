import { h, render } from 'preact';
import { RouterProvider } from 'react-router-dom';
import router from './router';

import '@styles/common.scss';
import '@styles/layout.scss';
import '@styles/color-schemes.scss';
import { getEnvironment } from '@services/EnvService';
import CSSVariables from '@services/CSSVariablesService';
import 'overlayscrollbars/overlayscrollbars.css';
import 'react-toastify/dist/ReactToastify.css';

const rootElement: HTMLElement | null = document.getElementById('app');

// first load always make color scheme based on the system settings and night mode or saved in local storage
// next color change will be handled by the Root.tsx component when user's settings are laoded (day or night mode can be changed then according set by user in settings page)
getEnvironment().then((env) => {
	if (env) {
		const isDayColorMode = localStorage.getItem('is_day_color_mode') === 'true' || false;
		CSSVariables.update(CSSVariables.buildColorScheme(env.color_accent, isDayColorMode));
	}
});

if (rootElement) {
	render(<RouterProvider router={router} />, rootElement);
}
